import { SharedLayoutComponent } from "@puntaje/platforms/ua"
import { SimpleSharedLayoutComponent } from "@puntaje/platforms/ua"
import {
    UsuariosEditComponent,
    UsuarioPerfilComponent,
    TutorConfirmarComponent,
    RequestConfirmationComponent,
    RecuperarCuentaComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    CompletarRegistroComponent,
    RegisterConfirmationComponent,
    IncompleteRegisterComponent,
    CompletarRegistroSocialComponent,
    UsuarioRegistradoComponent
} from "@puntaje/puntaje/core"
import { LoggedLayoutComponent } from "../layouts/logged_layout/logged_layout.component"
import { Routes, RouterModule } from "@angular/router"
import { AuthGuard } from "@puntaje/shared/core"
import { TokenGuard } from "@puntaje/puntaje/services"

import { RegisterUAComponent } from "./register/register-ua.component"

import {
    RegisterCompletarClonesComponent,
    RegisterIncompletoPuntajeComponent
} from "@puntaje/puntaje/new-modules/usuarios"

export const routes: Routes = [
    //{ path: 'usuarios', component: UsuariosComponent, canActivate: [TokenGuard, AuthGuard] },
    /*{ path: 'usuarios/login', component: LoginComponent },*/
    {
        path: "usuarios/recuperar_cuenta",
        component: SharedLayoutComponent,
        children: [{ path: "", component: RecuperarCuentaComponent }]
    },
    { path: "usuarios/register", component: RegisterUAComponent },
    { path: "usuarios/registro", component: RegisterUAComponent },
    {
        path: "usuarios/registrado",
        component: SharedLayoutComponent,
        children: [{ path: "", component: UsuarioRegistradoComponent }]
    },
    {
        path: "usuarios/:id/completar_registro",
        component: SharedLayoutComponent,
        children: [
            { path: "", component: CompletarRegistroComponent, data: { component: RegisterCompletarClonesComponent } }
        ]
    },
    {
        path: "usuarios/:id/completar_registro_social",
        component: SharedLayoutComponent,
        children: [{ path: "", component: CompletarRegistroSocialComponent }]
    },
    { path: "usuarios/confirmar_email/:token", component: RegisterConfirmationComponent },
    {
        path: "usuarios/confirmar_email",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: RequestConfirmationComponent }]
    },
    //{ path: 'usuarios/new', component: UsuariosNewComponent, canActivate: [TokenGuard, AuthGuard] },
    {
        path: "usuarios/edit",
        component: LoggedLayoutComponent,
        children: [
            {
                path: "",
                component: UsuariosEditComponent,
                canActivate: [TokenGuard, AuthGuard],
                data: {
                    enableFacebookLink: false,
                    enableTutor: true
                }
            }
        ]
    },
    {
        path: "usuarios/editar",
        component: LoggedLayoutComponent,
        children: [
            {
                path: "",
                component: UsuariosEditComponent,
                canActivate: [TokenGuard, AuthGuard],
                data: {
                    enableFacebookLink: false,
                    enableTutor: true
                }
            }
        ]
    },
    {
        path: "usuarios/:id",
        component: LoggedLayoutComponent,
        children: [
            { path: "", component: UsuarioPerfilComponent, canActivate: [TokenGuard, AuthGuard], data: { tab: "" } }
        ]
    },
    {
        path: "usuarios/passwords/reset/:reset_password_token",
        component: SimpleSharedLayoutComponent,
        children: [{ path: "", component: ResetPasswordComponent }]
    },
    {
        path: "usuarios/passwords/forgot",
        component: SimpleSharedLayoutComponent,
        children: [{ path: "", component: ForgotPasswordComponent }]
    },
    /*{ path: 'usuarios/:id/logros', component: UsuarioComponent, canActivate: [TokenGuard, AuthGuard], data: {tab: 'logros'} },*/
    //{ path: 'usuarios/:id/edit', component: UsuariosEditComponent, canActivate: [TokenGuard, AuthGuard] },
    //{ path: 'usuarios/:id/edicion', component: EdicionComponent, canActivate: [TokenGuard, AuthGuard] },
    //{ path: 'usuarios/:id/delete', component: UsuariosDeleteComponent, canActivate: [TokenGuard, AuthGuard] }
    {
        path: "tutor/confirmar/:token",
        component: SimpleSharedLayoutComponent,
        children: [{ path: "", component: TutorConfirmarComponent }]
    }
    // {
    //     path: "pending_info",
    //     component: IncompleteRegisterComponent,
    //     data: { component: RegisterIncompletoPuntajeComponent }
    // }
]

export const routing = RouterModule.forChild(routes)
