<div class="row universidad-images">
    <div class="col-md-8 col-lg-8">
        <img
            class="contained"
            [src]="config.ensayoNacional.brandingImage"
            alt="Campaña {{ config.ensayoNacional.nombreUniversidad }}"
        />
    </div>
    <div class="col-md-4 col-lg-4">
        <a [href]="config.ensayoNacional.zonaConLink.link" target="_blank">
            <img
                class="contained"
                [src]="config.ensayoNacional.zonaConLink.imagen"
                alt="Imagen banner becas {{ config.ensayoNacional.nombreUniversidad }}"
            />
        </a>
    </div>
</div>
<br />
<div class="row">
    <div class="col-md-12 col-lg-12">
        <p class="disclaimer text-center">
            <i>
                Al realizar estos ensayos autorizas que {{ config.ensayoNacional.nombreUniversidad }} te pueda contactar
                y/o enviar información sobre su oferta académica y proceso de admisión.
            </i>
        </p>
    </div>
</div>
<div class="row">
    <div class="ensayos col-md-12 col-lg-12">
        <menu-grid-layout [menuItems]="menuItems"></menu-grid-layout>
    </div>
</div>

>
