import { LoggedLayoutComponent } from "../layouts/logged_layout/logged_layout.component"
import {
    DiagnosticoComponent,
    PlanesPersonalesComponent,
    PlanPersonalSesionComponent,
    PlanPersonalComponent,
    HistorialPlanesCompartidosComponent,
    RealizarPlanCompartidoComponent
} from "@puntaje/puntaje/core"
import { EvaluationLayoutComponent } from "../layouts/evaluation_layout/evaluation_layout.component"
import { Routes, RouterModule } from "@angular/router"
import { AppConfig, AuthGuard } from "@puntaje/shared/core"
import { TokenGuard } from "@puntaje/puntaje/services"

import { HomePlanesPersonalesComponent } from "./home-planes-personales/home-planes-personales.component"

declare const config: AppConfig

export const routes: Routes = [
    {
        path: "mis_planes_personales",
        component: LoggedLayoutComponent,
        children: [
            {
                path: "",
                component: PlanesPersonalesComponent,
                canActivate: [TokenGuard, AuthGuard],
                data: {
                    get evaluacionTipos() {
                        return config.plataforma.evaluacionTipos.filter(
                            et => et !== "curricular" && !et.includes("diagnostico")
                        )
                    },
                    showTitleOverMenu: false
                }
            }
        ]
    },
    {
        path: "plan_personal/:id",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: PlanPersonalComponent, canActivate: [AuthGuard] }]
    },
    {
        path: "planes_personales",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: HomePlanesPersonalesComponent, canActivate: [TokenGuard, AuthGuard] }]
    },
    {
        path: "planes_compartidos",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: HistorialPlanesCompartidosComponent, canActivate: [TokenGuard, AuthGuard] }]
    },
    {
        path: "planes_compartidos/:id",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: RealizarPlanCompartidoComponent }]
    },
    {
        path: "diagnostico",
        component: EvaluationLayoutComponent,
        children: [
            {
                path: "",
                component: DiagnosticoComponent,
                data: {
                    rutaRedirect: "/mis_planes_personales"
                },
                canActivate: [TokenGuard, AuthGuard]
            }
        ]
    },
    {
        path: "plan_personal/:id/ciclo/:ciclo/sesiones/:sesion_id",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: PlanPersonalSesionComponent, canActivate: [TokenGuard, AuthGuard] }]
    }
]

export const routing = RouterModule.forChild(routes)
