import { LoggedLayoutService } from "@puntaje/shared/cui"
import { Component, OnInit, ViewChild, Input } from "@angular/core"
import { Router, ActivatedRoute } from "@angular/router"
import { SessionService } from "@puntaje/shared/core"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig

@Component({
    templateUrl: "home.component.html",
    styleUrls: ["home.component.scss"]
})
export class HomeComponent implements OnInit {
    config: typeof config
    @Input() pagina: string = "home"
    isPro = false
    dias = false
    dias_faltantes = 0
    frase: string = ""
    warningText =
        "<p>Debido a la nueva prueba de transición, la información histórica de ensayos PSU estará disponible hasta el <b>31 de mayo</b>.</p>"
    //@ViewChild('loadingLayout') loadingLayout: LoadingLayoutComponent;
    asignaturasBoxInsteadPlanClase = true

    evaluacionesData = {
        1: [
            {
                evId: 1284293,
                nombre: "Mini ensayo N° 1"
            },
            {
                evId: 1284298,
                nombre: "Mini ensayo N° 2"
            }
        ],
        2: [
            {
                evId: 1284300,
                nombre: "Mini ensayo N° 1"
            },
            {
                evId: 1284305,
                nombre: "Mini ensayo N° 2"
            }
        ],
        3: [
            {
                evId: 1284309,
                nombre: "Mini ensayo N° 1"
            },
            {
                evId: 1284314,
                nombre: "Mini ensayo N° 2"
            }
        ],
        5: [
            {
                evId: 1284349,
                nombre: "Mini ensayo N° 1"
            },
            {
                evId: 1284357,
                nombre: "Mini ensayo N° 2"
            }
        ],
        6: [
            {
                evId: 1284328,
                nombre: "Mini ensayo N° 1"
            },
            {
                evId: 1284334,
                nombre: "Mini ensayo N° 2"
            }
        ],
        7: [
            {
                evId: 1284338,
                nombre: "Mini ensayo N° 1"
            },
            {
                evId: 1284344,
                nombre: "Mini ensayo N° 2"
            }
        ]
    }

    evaluacionesDemre = {
        1: {
            evId: 1284362,
            nombre: "Ensayo Modelo DEMRE admisión 2020"
        },
        2: {
            evId: 1284372,
            nombre: "Ensayo Modelo DEMRE admisión 2020"
        },
        3: {
            evId: 1284379,
            nombre: "Ensayo Modelo DEMRE admisión 2020"
        },
        5: {
            evId: 1284396,
            nombre: "Ensayo Modelo DEMRE admisión 2020"
        },
        6: {
            evId: 1284392,
            nombre: "Ensayo Modelo DEMRE admisión 2020"
        },
        7: {
            evId: 1284401,
            nombre: "Ensayo Modelo DEMRE admisión 2020"
        }
    }

    constructor(
        protected router: Router,
        protected route: ActivatedRoute,
        protected sessionService: SessionService,
        protected loggedLayoutService: LoggedLayoutService
    ) {
        this.config = config
    }

    ngOnInit() {
        this.isPro = this.sessionService.isPro()
        this.loggedLayoutService.setOptions({ freeContentStyle: true })
    }
}
