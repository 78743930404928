import { ActivatedRouteSnapshot, RouterStateSnapshot, CanActivate } from "@angular/router"
import { AuthService } from "@puntaje/shared/core"
import { Injectable } from "@angular/core"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig

@Injectable({
    providedIn: "root"
})
export class EnsayoNacionalRedirectGuard implements CanActivate {
    constructor(public authService: AuthService) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (config.ensayoNacional && config.ensayoNacional.enableRedirectOnLogin && !this.authService.isLoggedIn()) {
            this.authService.setRedirectAfterRegister(state.url.toString())
        }
        return true
    }
}
