import { Component, OnInit, Output, EventEmitter, Input, ViewChild, ElementRef } from "@angular/core"
import { Usuarios } from "@puntaje/puntaje/api-services"
import { BrowserQRCodeReader } from "@zxing/library"
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"

export type ValidationStatus = {
    status: boolean
    serial: string
}

@Component({
    selector: "alumno-recuperar-cuenta",
    templateUrl: "./alumno-recuperar-cuenta.component.html",
    styleUrls: ["./alumno-recuperar-cuenta.component.scss"]
})
export class AlumnoRecuperarCuentaComponent implements OnInit {
    constructor(protected usuariosService: Usuarios) {}
    serial: string
    enableQr: boolean
    notSameRut: boolean
    notValidRut: boolean
    imgUrl: string | ArrayBuffer

    @Input() rut: string
    @Output() onValidation = new EventEmitter<ValidationStatus>()

    // @ViewChild("img", { static: false }) img: ElementRef
    @ViewChild(LoadingLayoutComponent, { static: true }) loadingLayout: LoadingLayoutComponent

    ngOnInit() {}

    ngAfterViewInit() {
        this.loadingLayout.ready()
    }

    validarRutSii() {
        this.loadingLayout.standby()
        this.usuariosService.validarRutSii(this.rut, this.serial).then(valido => {
            this.notValidRut = !valido
            this.loadingLayout.ready()
            if (valido) this.onValidation.emit({ status: valido, serial: this.serial })
        })
    }

    scanSuccessHandler(qrString) {
        let rutRegexp = /RUN=([\w|-]+)/
        let serialRegexp = /serial=(\w+)/

        let rut = qrString.match(rutRegexp)[1]

        if (rut != this.rut) {
            this.notSameRut = true
        } else {
            this.notSameRut = false
            this.serial = qrString.match(serialRegexp)[1]
            this.validarRutSii()
        }
    }

    // async processImageUpload(imageInput: any) {
    //     var reader = new FileReader()
    //     reader.readAsDataURL(imageInput.files[0])
    //     reader.onload = async _event => {
    //         this.img.nativeElement.setAttribute("src", reader.result as string)
    //         let result = await new BrowserQRCodeReader().decodeFromImage(this.img.nativeElement)
    //         this.scanSuccessHandler(result.getText())
    //     }
    // }
}
