<div class="contenedor">
    <!-- Login -->
    <div id="section1">
        <div class="fading-image" [lazyLoad]="landingImage" [offset]="200"></div>
        <div class="content-imgs">
            <!-- <div class="logo-izq-container">
                <img class="logo-izq" src="assets/img/logos/logoMediumLight.png" />
            </div> -->
            <div class="logo-bottom-container">
                <img class="logo-bottom" src="assets/img/LOGOS-PREU-UA-peque.png" />
            </div>
            <div class="logo-bottom-container">
                <img class="logo-bottom" src="assets/img/siguenos.png" />
            </div>
        </div>
        <div class="content-login">
            <div class="content-login-wrap">
                <div class="content-login-img">
                    <img src="assets/img/logos/logoMediumLight.png" />
                </div>
                <login-ua
                    [enableContactUs]="false"
                    [enableRegister]="true"
                    [enableFacebookLogin]="true"
                    [enablePasswordRecovery]="true"
                ></login-ua>
            </div>
        </div>
    </div>

    <div id="section2">
        <div class="contentInformacion">
            <p>
                ¡Este proceso de Admisión 2025 estamos contigo!
                <br />
                Conoce nuestro preuniversitario online gratis, inscríbete y comienza a prepararte para la Prueba de
                Acceso a la Educación
                <br />
                Superior (PAES) para que alcances la meta de estudiar la carrera de tus sueños y convertirte en
                profesional.
            </p>
        </div>
    </div>
    <div id="section3">
        <div class="content-img">
            <img class="imagen-acceso-gratuito" src="assets/img/landing_detalle.jpg" />
        </div>
    </div>
    <footer-component [showColNavegacion]="false" [showBottomFooter]="true"></footer-component>
</div>
<simple-modal></simple-modal>
