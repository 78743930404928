import { Component, OnInit } from "@angular/core"
import { Router, ActivatedRoute } from "@angular/router"
import { AuthService } from "@puntaje/shared/core"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig

@Component({
    selector: "landing",
    templateUrl: "landing.component.html",
    styleUrls: ["landing.component.scss"]
})
export class LandingComponent implements OnInit {
    config: typeof config
    landingImage: string
    // landingImage: string = "https://bucket-puntaje-reloaded.s3.us-west-1.amazonaws.com/ua/landing/fondo_landing_ua.png"

    constructor(private router: Router, private route: ActivatedRoute, public authService: AuthService) {}

    ngOnInit() {
        this.config = config

        this.landingImage = this.config.app.baseStyle?.landing?.backgroundImage
            ? this.config.app.baseStyle?.landing?.backgroundImage
            : "https://bucket-puntaje-reloaded.s3-us-west-1.amazonaws.com/shared/base/fondo_base.jpg"

        if (this.authService.isLoggedIn()) {
            this.router.navigate(["home"])
        }
    }
}
