<loading-layout>
    <span *ngIf="!enableQr">
        <form>
            <div class="form-group">
                <label for="rut">Rut</label>
                <input [disabled]="true" name="rut" [ngModel]="rut" type="text" class="form-control" />
            </div>
            <div class="form-group">
                <label for="serial">Serial</label>
                <input name="serial" [(ngModel)]="serial" type="text" class="form-control" />
            </div>
            <button [disabled]="!rut || !serial" class="btn btn-primary" type="submit" (click)="validarRutSii()">
                Validar
            </button>
        </form>
    </span>
    <p class="alert alert-warning" *ngIf="notSameRut">
        El rut ingresado no coincide con el rut de tu cédula de identidad.
    </p>
    <p class="alert alert-warning" *ngIf="notValidRut">El rut o serial no son válidos</p>
    <a class="hidden-xs" (click)="enableQr = !enableQr">
        {{ enableQr ? "Validar completando datos" : "Validar escaneando QR" }}
    </a>
    <zxing-scanner *ngIf="enableQr" (scanSuccess)="scanSuccessHandler($event)"></zxing-scanner>
    <!-- <input #imageInput type="file" accept="image/*" (change)="processImageUpload(imageInput)" /> -->
    <!-- <img class="img-responsive" #img /> -->
</loading-layout>
