<cui-logged-layout [freeContentStyle]="false">
    <cui-layout-header>
        <ng-container *ngIf="authService.isLoggedIn()">
            <div *ngIf="!confirmedAccount" class="row novalidated text-center">
                Valida tu correo y celular. Es importante para poder recuperar tu contraseña.
                <a [routerLink]="['/usuarios/registrado']">Validar</a>
            </div>
            <navbar></navbar>
        </ng-container>
    </cui-layout-header>
    <cui-layout-footer>
        <footer-component></footer-component>
        <typeform-widgets-view></typeform-widgets-view>
    </cui-layout-footer>
</cui-logged-layout>
