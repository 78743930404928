<ll-over-title>
    <arte-home-showcasebox-barra [pagina]="pagina"></arte-home-showcasebox-barra>
</ll-over-title>
<ll-titulo>
    Bienvenid@
    <br />
    <span>PAES</span>
</ll-titulo>

<div class="row">
    <div class="col-md-4 col-sm-6">
        <notificaciones-showcasebox
            [maximoNotificaciones]="3"
            [enableContentStyle]="false"
        ></notificaciones-showcasebox>
        <noticias-alumnos-showcasebox></noticias-alumnos-showcasebox>
    </div>
    <div class="col-md-4 col-sm-6">
        <planes-estudios-showcasebox
            [asignaturasBoxInsteadPlanClase]="asignaturasBoxInsteadPlanClase"
            [enablePlanesPersonales]="true"
            [maxColumns]="1"
        ></planes-estudios-showcasebox>
    </div>
    <div class="col-md-4 col-sm-6">
        <arte-home-showcasebox [pagina]="pagina"></arte-home-showcasebox>
        <br />
    </div>
</div>

<arte-zona-modal [pagina]="pagina"></arte-zona-modal>
