import { Component, OnInit } from "@angular/core"
import { Angulartics2Facebook } from "angulartics2/facebook"
import { ActivatedRoute } from "@angular/router"
import { Subscription } from "rxjs"

@Component({
    templateUrl: "register-ua.component.html",
    styleUrls: ["register-ua.scss"]
})
export class RegisterUAComponent implements OnInit {
    unloggedLayoutTitle = "Hazte una cuenta"
    sub: Subscription
    tipo: any

    constructor(protected angularticsFb: Angulartics2Facebook, private route: ActivatedRoute) {}

    ngOnInit() {
        this.sub = this.route.queryParams.subscribe(params => {
            this.tipo = params["tipo"]
        })
    }

    onTitleChange(title) {
        this.unloggedLayoutTitle = title
    }

    onUsuarioRegistrado(event) {
        this.angularticsFb.eventTrack("CompleteRegistration")
    }
}
