import {
    PlanClasesDeAlumnosComponent,
    PlanClaseAlumnoComponent,
    ClasePlanClaseComponent,
    PlanesEstudiosComponent,
    PlanEstudioSesionComponent,
    PlanEstudioSesionesComponent
} from "@puntaje/puntaje/core"
import { LoggedLayoutComponent } from "../layouts/logged_layout/logged_layout.component"
import { Routes, RouterModule } from "@angular/router"

import { HomePlanesEstudiosComponent } from "./home_planes_estudios.component"
import { AuthGuard } from "@puntaje/shared/core"
import { TokenGuard } from "@puntaje/puntaje/services"

export const routes: Routes = [
    {
        path: "plan_clases",
        component: LoggedLayoutComponent,
        children: [
            {
                path: "",
                component: PlanClasesDeAlumnosComponent,
                canActivate: [TokenGuard, AuthGuard],
                data: {
                    titulo: "Planes de Clases",
                    planClaseRoute: "/plan_clases"
                }
            }
        ]
    },
    {
        path: "asignatura/:asignatura_id/planes_estudio",
        component: LoggedLayoutComponent,
        children: [
            {
                path: "",
                component: PlanClasesDeAlumnosComponent,
                canActivate: [TokenGuard, AuthGuard]
            }
        ]
    },
    {
        path: "plan_clases/:id/sesiones/:sesion_id",
        component: LoggedLayoutComponent,
        children: [
            {
                path: "",
                component: ClasePlanClaseComponent,
                canActivate: [TokenGuard, AuthGuard],
                data: {
                    titulo: "Sesión",
                    planUrl: "/plan_clases",
                    isLibroFrom: "alumno",
                    enableReporte: true,
                    showVerLibro: true
                }
            }
        ]
    },
    {
        path: "plan_clases/:id",
        component: LoggedLayoutComponent,
        children: [
            {
                path: "",
                component: PlanClaseAlumnoComponent,
                canActivate: [TokenGuard, AuthGuard],
                data: {
                    titulo: "Sesiones - Plan de Clase",
                    baseRoute: "/plan_clases"
                }
            }
        ]
    },
    {
        path: "plan_clases",
        component: LoggedLayoutComponent,
        children: [
            {
                path: "",
                component: HomePlanesEstudiosComponent,
                canActivate: [TokenGuard, AuthGuard]
            }
        ]
    }
]

export const routing = RouterModule.forChild(routes)
