<footer>
    <ng-container *ngIf="showBottomFooter">
        <div class="bottom-footer">
            <img class="footer-logo" src="/assets/img/CNA.png" alt="CNA" />
            <img class="footer-logo" src="/assets/img/ANECA.png" alt="ANECA" />
            <img class="footer-logo" src="/assets/img/AQAS.png" alt="AQAS" />
        </div>
        <div class="bottom-footer no-border">
            <img class="footer-logo" src="/assets/img/GRATUIDAD.png" alt="Adscrita gratuidad" />
            <img class="footer-logo" src="/assets/img/THE.png" alt="THE" />
        </div>
        <div class="contacto">
            <p class="text-center">Si no puedes acceder, escríbenos a <b>contactoua@ogr.cl</b></p>
        </div>
    </ng-container>
</footer>
