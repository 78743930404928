import { Component, OnInit } from "@angular/core"
import { TitleService } from "@puntaje/shared/core"

@Component({
    selector: "app-recuperar-cuenta",
    templateUrl: "./recuperar-cuenta.component.html",
    styleUrls: ["./recuperar-cuenta.component.scss"]
})
export class RecuperarCuentaComponent implements OnInit {
    constructor(protected titleService: TitleService) {}

    ngOnInit() {
        this.titleService.setTitle("titles.core.usuarios.recuperar_cuenta")
    }
}
