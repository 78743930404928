import { Component, Input } from "@angular/core"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig

/*#################################

Footer, incluye información de la empresa.

#################################*/

@Component({
    templateUrl: "footer.component.html",
    selector: "footer-component",
    styleUrls: ["footer.component.scss"]
})
export class FooterComponent {
    supportMail: string
    contactPhone: string
    config: typeof config

    @Input() showColNavegacion = true
    @Input() showBottomFooter = true

    facultades = [
        {
            nombre: "Administración y Negocios",
            carreras: [
                {
                    nombre: "Auditoría e Ingeniería en Control de Gestión",
                    url: "https://admision.uautonoma.cl/carreras/auditoria-e-ingenieria-en-control-de-gestion/"
                },

                { nombre: "Ingeniería Comercial", url: "https://admision.uautonoma.cl/carreras/ingenieria-comercial/" },

                {
                    nombre: "Ingeniería en Administración",
                    url: "https://admision.uautonoma.cl/carreras/ingenieria-en-administracion-de-empresas/"
                }
            ]
        },
        {
            nombre: "Arquitectura y Construcción",
            carreras: [
                { nombre: "Arquitectura", url: "https://admision.uautonoma.cl/carreras/arquitectura/" },

                {
                    nombre: "Ingeniería en Construcción",
                    url: "https://admision.uautonoma.cl/carreras/ingenieria-en-construccion-2/"
                }
            ]
        },
        {
            nombre: "Ciencias de la Salud",
            carreras: [
                { nombre: "Enfermería", url: "https://admision.uautonoma.cl/carreras/enfermeria/" },

                { nombre: "Fonoaudiología", url: "https://admision.uautonoma.cl/carreras/fonoaudiologia/" },

                { nombre: "Kinesiología", url: "https://admision.uautonoma.cl/carreras/kinesiologia/" },

                { nombre: "Medicina", url: "https://admision.uautonoma.cl/carreras/medicina/" },

                {
                    nombre: "Nutrición y Dietética",
                    url: "https://admision.uautonoma.cl/carreras/nutricion-y-dietetica/"
                },

                {
                    nombre: "Obstetricia y Puericultura",
                    url: "https://admision.uautonoma.cl/carreras/obstetricia-y-puericultura/"
                },

                { nombre: "Odontología", url: "https://admision.uautonoma.cl/carreras/odontologia/" },

                { nombre: "Química y Farmacia", url: "https://admision.uautonoma.cl/carreras/quimica-y-farmacia/" },

                { nombre: "Terapia Ocupacional", url: "https://admision.uautonoma.cl/carreras/terapia-ocupacional/" }
            ]
        },
        {
            nombre: "Ciencias Sociales y Humanidades",
            carreras: [
                {
                    nombre: "Administración Pública",
                    url: "https://admision.uautonoma.cl/carreras/administracion-publica/"
                },

                { nombre: "Periodismo", url: "https://admision.uautonoma.cl/carreras/periodismo/" },

                { nombre: "Psicología", url: "https://admision.uautonoma.cl/carreras/psicologia/" },

                {
                    nombre: "Publicidad y Comunicación Integral",
                    url: "https://admision.uautonoma.cl/carreras/publicidad-y-comunicacion-integral/"
                },

                { nombre: "Relaciones Públicas", url: "https://admision.uautonoma.cl/carreras/relaciones-publicas/" },

                { nombre: "Trabajo Social", url: "https://admision.uautonoma.cl/carreras/trabajo-social/" }
            ]
        },
        {
            nombre: "Derecho",
            carreras: [
                {
                    nombre: "Derecho",
                    url: "https://admision.uautonoma.cl/carreras/derecho/"
                }
            ]
        },
        {
            nombre: "Educación",
            carreras: [
                {
                    nombre: "Licenciatura en Artes Visuales",
                    url: "https://admision.uautonoma.cl/carreras/licenciatura-en-artes-visuales/"
                },

                {
                    nombre: "Pedagogía en Educación Básica",
                    url: "https://admision.uautonoma.cl/carreras/pedagogia-en-educacion-basica/"
                },

                {
                    nombre: "Pedagogía en Educación Diferencial",
                    url: "https://admision.uautonoma.cl/carreras/pedagogia-en-educacion-diferencial/"
                },

                {
                    nombre: "Pedagogía en Educación Física",
                    url: "https://admision.uautonoma.cl/carreras/pedagogia-en-educacion-fisica/"
                },

                {
                    nombre: "Pedagogía en Educación Parvularia",
                    url: "https://admision.uautonoma.cl/carreras/pedagogia-en-educacion-parvularia/"
                },

                {
                    nombre: "Pedagogía en Historia, Geografía y Cs. Sociales",
                    url: "https://admision.uautonoma.cl/carreras/pedagogia-en-historia-geografia-y-cs-sociales/"
                },

                { nombre: "Pedagogía en Inglés", url: "https://admision.uautonoma.cl/carreras/pedagogia-en-ingles/" },

                {
                    nombre: "Pedagogía en Lengua Castellana y Comunicación",
                    url: "https://admision.uautonoma.cl/carreras/pedagogia-en-lengua-castellana-y-comunicacion/"
                }
            ]
        },
        {
            nombre: "Ingeniería",
            carreras: [
                {
                    nombre: "Ingeniería Civil Industrial",
                    url: "https://admision.uautonoma.cl/carreras/ingenieria-civil-industrial/"
                },

                {
                    nombre: "Ingeniería Civil Informática",
                    url: "https://admision.uautonoma.cl/carreras/ingenieria-civil-informatica/"
                },

                {
                    nombre: "Ingeniería Civil Química",
                    url: "https://admision.uautonoma.cl/carreras/ingenieria-civil-quimica/"
                }
            ]
        }
    ]

    constructor() {
        this.config = config
    }
}
