import { LoggedLayoutService } from "@puntaje/shared/cui"
import { Component } from "@angular/core"
import { MenuItemBoxData } from "@puntaje/shared/layouts"
import { Clasificacion, Clasificaciones } from "@puntaje/nebulosa/api-services"
import { AuthService, TabService } from "@puntaje/shared/core"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig

@Component({
    templateUrl: "home_biblioteca.component.html"
})
export class HomeBibliotecaComponent {
    config: typeof config = config
    cursos: Clasificacion[]
    menuItems: MenuItemBoxData[] = []
    enableMenu: boolean = false
    usuarioId: number

    constructor(
        protected csService: Clasificaciones,
        protected tabService: TabService,
        protected authService: AuthService,
        protected loggedLayoutService: LoggedLayoutService
    ) {
        this.usuarioId = this.authService.getUserData().id
    }

    ngOnInit() {
        this.setMenuItems()

        this.loggedLayoutService.setOptions({ freeContentStyle: true })
    }

    setMenuItems() {
        this.menuItems = [
            {
                route: "/biblioteca/",
                params: { tipo_instrumento: "paes" },
                label: "Material PAES",
                text: "Estudia para la PAES con guías y videos organizados por asignatura y eje temático.",
                linkText: "Ver material PAES",
                icon: "libro2-o"
            },
            {
                route: "/usuarios/" + this.usuarioId,
                label: "Material Favorito",
                text: "Explora y administra la lista de materiales que has marcado como favoritos.",
                linkText: "Ver materiales favoritos",
                icon: "material-favorito-o",
                action: this.goToFavoritos.bind(this)
            }
        ]
        this.enableMenu = true
    }

    goToFavoritos() {
        if (this.usuarioId != null) {
            this.tabService.setComponentTab("UsuarioComponent", "favoritos")
        }
    }
}
