import { LoggedLayoutService } from "@puntaje/shared/cui"
import { Component } from "@angular/core"
import { MenuItemBoxData } from "@puntaje/shared/layouts"
import { MenuComponent } from "@puntaje/puntaje/new-modules/shared"
import { AuthService } from "@puntaje/shared/core"
import { GrupoUsuarios, GrupoUsuario } from "@puntaje/puntaje/api-services"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig
import { State } from "@puntaje/puntaje/store"
import { Store } from "@ngrx/store"

@Component({
    templateUrl: "home_pruebas_transicion.component.html",
    styleUrls: ["home_pruebas_transicion.component.scss"]
})
export class HomePruebasTransicionComponent extends MenuComponent {
    menuItems: MenuItemBoxData[] = []
    disableMenuItem: boolean = false
    config: typeof config = config

    constructor(
        protected store: Store<State>,
        protected grupoUsuariosService: GrupoUsuarios,
        protected authService: AuthService,
        protected loggedLayoutService: LoggedLayoutService
    ) {
        super(store)
    }

    ngOnInit() {
        super.ngOnInit()
        this.setMenuItems()

        this.loggedLayoutService.setOptions({ freeContentStyle: true })
    }

    async setMenuItems() {
        const asignaturasByEvaluacionTipo = await this.asignaturasByEvaluacionTipo$.toPromise()

        this.menuItems = [
            {
                route: "/pruebas_ensayos/generar",
                params: { tipo_instrumento: "paes" },
                label: "Realizar Ensayo",
                text: "Practica realizando ensayos similares a las pruebas del DEMRE.",
                linkText: "Comenzar",
                icon: "hacer-prueba"
            },
            {
                route: "/pruebas_ensayos/resultados/",
                params: { tipo_instrumento: "paes" },
                label: "Historial de Ensayos",
                text: "Encuentra aquí la lista de ensayos que has realizado.",
                linkText: "Ver historial",
                icon: "guia"
            },
            {
                route: "/pruebas_ensayos/recomendados",
                params: { tipo_instrumento: "paes" },
                label: "Ensayos recomendados",
                text: "Practica realizando ensayos recomendados.",
                linkText: "Comenzar",
                icon: "hacer-prueba"
            },
            {
                route: "/pruebas_ensayos/estadisticas/",
                params: { tipo_instrumento: "paes" },
                label: "Estadísticas de Ensayos",
                text: "Encuentra aquí estadísticas generadas a partir de tus resultados en Ensayos.",
                linkText: "Ver estadísticas",
                icon: "estadistica-o"
            },
            {
                route: "/pruebas_ensayos/mi_progreso/",
                params: { tipo_instrumento: "paes" },
                label: "Mi Progreso",
                text: "Observa la evolución en el tiempo de tus resultados obtenidos.",
                linkText: "Ver mi progreso",
                icon: "estadistica-o"
            }
        ]
    }
}
