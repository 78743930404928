import { Component, OnInit, Output, ViewChild, EventEmitter, HostListener, Input } from "@angular/core"

import { Asignatura, Asignaturas } from "@puntaje/nebulosa/api-services"

import { MenuComponent } from "@puntaje/puntaje/new-modules/shared"
import { Observable } from "rxjs"
import { Store } from "@ngrx/store"
import { State } from "@puntaje/puntaje/store"

/*#################################

Menú arriba del sitio.

#################################*/

@Component({
    templateUrl: "topmenu.component.html",
    selector: "topmenu",
    styleUrls: ["topmenu.component.scss"]
})
export class TopmenuComponent extends MenuComponent implements OnInit {
    @Output() toggleTopmenuEvent = new EventEmitter()
    @ViewChild("topmenu") insideElement
    @HostListener("document:click", ["$event.target"]) public onClick(targetElement) {
        const clickedInside = this.insideElement.nativeElement.contains(targetElement)
        if (this.catchOutsideClick && !clickedInside) {
            this.toggleTopmenuEvent.emit()
        }
    }
    catchOutsideClick: boolean = false
    itemsMenu: any[]
    ITEMS_MENU = [
        {
            name: "Generador de ensayos",
            route: "/pruebas_ensayos",
            icon: "prueba"
        },
        {
            name: "Biblioteca",
            route: "/bibliotecas",
            icon: "libro2-o"
        },
        {
            name: "Plan de Clases",
            route: "/plan_clases",
            icon: "planificacion-o"
        },
        {
            icon: "planificacion-o",
            name: "Planes Personalizados",
            route: "/mis_planes_personales"
        }
    ]

    @Input() config: any

    constructor(store: Store<State>) {
        super(store)
    }

    ngOnInit() {
        super.ngOnInit()
        setTimeout(() => {
            this.catchOutsideClick = true
        }, 300)
        this.itemsMenu = this.ITEMS_MENU
    }
    onMenuClickOutside() {
        this.toggleTopmenuEvent.emit()
    }

    close() {
        this.toggleTopmenuEvent.emit()
    }
}
