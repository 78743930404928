import { Component, OnInit } from "@angular/core"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig
import { Router } from "@angular/router"

@Component({
    selector: "ensayo-nacional-universidad",
    templateUrl: "./ensayo-nacional-universidad.component.html",
    styleUrls: ["./ensayo-nacional-universidad.component.scss"]
})
export class EnsayoNacionalUniversidadComponent implements OnInit {
    config: typeof config

    menuItems = this.getMenuItems()

    getMenuItems() {
        let items = []
        if (config.ensayoNacional) {
            config.ensayoNacional.evaluaciones.forEach(elem => {
                items.push({
                    route: "/ensayos/" + elem.evaluacionId + "/realizar",
                    params: {
                        tipo_instrumento: elem.evaluacionTipo
                    },
                    label: elem.asignatura,
                    text: "",
                    linkText: "Comenzar",
                    icon: "hacer-prueba"
                })
            })
        }

        return items
    }

    constructor(protected router: Router) {
        this.config = config
    }

    ngOnInit() {
        if (!config.ensayoNacional || (config.ensayoNacional && !config.ensayoNacional.enabled)) {
            this.router.navigate(["/home"])
        }
    }
}
