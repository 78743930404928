<div class="background"></div>
<div class="content-container">
    <div class="row">
        <div class="col-lg-6 col-lg-offset-3 col-md-8 col-md-offset-2 col-sm-8 col-sm-offset-2 col-xs-12">
            <div class="container-content">
                <div class="logos-container">
                    <div class="logo-ua">
                        <a routerLink="/home">
                            <img
                                src="https://bucket-puntaje-reloaded.s3.us-west-1.amazonaws.com/ua/landing/register/logo.png"
                                alt=""
                            />
                        </a>
                    </div>
                </div>
                <div class="logo-preua">
                    <img
                        src="/assets/img/LOGO-PREUA.png"
                        alt=""
                    />
                </div>
                <div class="title-step" [hidden]="unloggedLayoutTitle !== 'Datos personales'">
                    <img
                        src="https://bucket-puntaje-reloaded.s3.us-west-1.amazonaws.com/ua/landing/register/datos-personales.png"
                        alt=""
                    />
                </div>
                <div class="title-step" [hidden]="unloggedLayoutTitle !== 'Datos de ingreso'">
                    <img
                        src="https://bucket-puntaje-reloaded.s3.us-west-1.amazonaws.com/ua/landing/register/datos-ingreso.png"
                        alt=""
                    />
                </div>
                <div class="title-step" [hidden]="unloggedLayoutTitle !== 'Datos académicos'">
                    <img
                        src="https://bucket-puntaje-reloaded.s3.us-west-1.amazonaws.com/ua/landing/register/datos-academicos.png"
                        alt=""
                    />
                </div>
                <div class="form-content" #sharedLayoutContenido>
                    <usuario-registro-ua
                        (onTitleChange)="onTitleChange($event)"
                        (onUsuarioRegistrado)="onUsuarioRegistrado($event)"
                        [tipoRegistro]="tipo"
                    ></usuario-registro-ua>
                </div>
                <div class="avisos">
                    <div class="sub-content">
                        <div class="img-sub">
                            <img
                                class="img-responsive"
                                src="https://bucket-puntaje-reloaded.s3.us-west-1.amazonaws.com/ua/landing/register/sub-2.png"
                                alt=""
                            />
                        </div>
                        <div class="img-sub">
                            <img
                                class="img-responsive"
                                src="https://bucket-puntaje-reloaded.s3.us-west-1.amazonaws.com/ua/landing/register/sub-3.png"
                                alt=""
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>
